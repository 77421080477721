.text-is-dark {
  color: $dark;
}
.text-is-primary {
  color: $primary;
}
.text-is-link {
  color: $link;
}
.text-is-grey {
  color: $grey;
}
.text-is-light {
  color: $light;
}
.text-is-link-light {
  color: $link-light;
}
.text-is-grey-light {
  color: $grey-light;
}

.has-text-brown {
  color: #bda893 !important;
}

.has-text-kiwi {
  color: #698589 !important;
}

.has-text-tomato {
  color: #d9575a !important;
}

.has-text-apple {
  color: #83d586 !important;
}

.has-text-lime {
  color: #f6c34a !important;
}

.has-text-orange {
  color: #f08832 !important;
}

.has-text-raspberry {
  color: #fc3f65 !important;
}

.has-text-cherry {
  color: #9b195f !important;
}

.has-text-blueberry {
  color: #586f6d !important;
}

.content.is-small {
  letter-spacing: 0.4px;
}

th {
  @extend .is-bold;
}
table {
  font-size: $size-7;
  @include fullhd {
    font-size: $size-6;
  }
}

// Main rules

.light {
  font-weight: 300 !important;
}

.is-size-8 {
  font-size: $size-8;
}

.is-medium {
  font-weight: 500;
}
//Title

.title {
  color: $dark;
}

h1.title.is-2 {
  > .icon {
    position: relative;
    top: 1.5px;
  }
}

h1,
.title.is-1,
.h1 {
  font-weight: 100;

  @include touch {
    font-size: 3.2rem;
  }
}

.title--small {
  @extend .label;
  @extend .is-size-8;
  @extend .m-b-0;
}
// Badges

.badge {
  height: 25px;
  margin: 2.5px 5px 2.5px 0;
  padding: 2px 5px 0 5px !important;
  font-weight: 500;
  @include inline-flex;
  @include justify-content(center);
  @include align-items(center);

  @include touch {
    font-size: 0.825em;
  }
  &.is-info {
    background-color: $info-light;
    color: $link;
  }
  &.is-success {
    background-color: $success-light;
    color: $success;
  }
  &.is-link {
    background-color: $link-light;
    color: $link;
  }
}

.total {
  position: absolute;
  top: 0;
  right: 30px;

  &.aligned-to-title {
    top: -30px;
  }

  &--alt {
    right: 0;
    top: unset;
    bottom: 0;
  }
}

//_ Global rules
hr {
  height: 1px;
  background-color: $border-color;
}

a {
  &.no-deco {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }
  &[routerLink],
  &[href],
  &[ng-reflect-router-link] {
    cursor: pointer;

    .main &:not(.button):hover {
      text-decoration: underline;
    }
  }

  &.become-link {
    color: $grey;
    &:hover,
    &:focus,
    &:active {
      color: $link !important;
    }
  }
}

.is-italic {
  font-style: italic;
}
.no-transfo {
  text-transform: none;
}
.is-regular {
  font-weight: 400;
}

a,
button,
.button {
  &.is-disabled,
  &[disabled] {
    cursor: no-drop;
    color: $grey-light !important;
  }
}
//_ Specific rules
.status-text {
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.coma-list {
  display: inline-block;
  + .coma-list {
    &:before {
      content: ',';
      padding-right: 2px;
    }
  }
}

//_ Tooltip

.tooltip {
  &:before {
    background-color: $dark !important;
    border: 1px solid $dark;
    color: $light !important;
    font-size: 1.3rem !important;
    z-index: 99;
    text-transform: none;
    text-align: left;
  }
  &[data-tooltip]:not(.is-disabled).has-tooltip-bottom.has-tooltip-multiline::before,
  [data-tooltip]:not(.is-loading).has-tooltip-bottom.has-tooltip-multiline::before,
  [data-tooltip]:not([disabled]).has-tooltip-bottom.has-tooltip-multiline::before {
    width: max-content;
    max-width: 300px;
    white-space: pre-wrap;
    overflow: auto;
    height: auto;
  }
}

// _ Tags

.tag--event {
  padding: 8px 12px !important;
  margin: 0 $gap 0 0 !important;
  border-radius: 3px !important;
  > b {
    background: -webkit-linear-gradient($warning, $danger);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.visible-on-hover {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  @include transition(all 0.18s ease-in-out);

  &:hover {
    opacity: 1;
  }
}

.is-default,
.is-default a {
  cursor: default;
}

.has-text-success {
  color: $success;
}

.has-tooltip-left {
  .tooltip.has-tooltip {
    &:before {
      width: 30rem;
      max-width: 30rem;
      top: auto;
      right: auto;
      bottom: 50%;
      left: -11px;
      transform: translate(-100%, 50%);
    }
    &:after {
      top: auto;
      right: auto;
      bottom: 50%;
      left: 0;
      margin-top: auto;
      margin-right: auto;
      margin-bottom: -6px;
      margin-left: -11px;
      border-color: #0000 #0000 #0000 #4a4a4ae6;
    }
  }
}
