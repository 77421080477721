@use 'sass:math';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500');

// Variables
@import './variables';

// Bulma
@import 'bulma/bulma';
@import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';

// Angular calendar
@import '../../node_modules/angular-calendar/scss/angular-calendar';

// Mixins & helpers
@import './commons/helpers';
@import './commons/mixins';

// commons
@import './commons/buttons';
@import './commons/cards';
@import './commons/forms';
@import './commons/grid';
@import './commons/icons';
@import './commons/layout';
@import './commons/lists';
@import './commons/nav';
@import './commons/type';

// Components
@import './components/buttons';
@import './components/breadcrumbs';
@import './components/calendar';
@import './components/cards';
@import './components/forms';
@import './components/grid';
@import './components/icons';
@import './components/layout';
@import './components/lists';
@import './components/modals';
@import './components/nav';
@import './components/spinner';
@import './components/table';
@import './components/type';
