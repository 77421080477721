:root {
  --border-color: #f7f7f7;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
}

html {
  font-size: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  @include touch {
    font-size: 10.22px;
  }

  @include mobile {
    font-size: 7.22px;
  }

  &.is-clipped {
    position: fixed;
    overflow: hidden !important;

    body {
      position: fixed;
      overflow: hidden !important;
    }
  }
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background: $main-background;
  color: $black;
  font-size: 1.3rem;
}

.wrapper {
  padding: 0 15px;
  width: 100vw;
}

.wrapper,
.aside {
  min-height: calc(100vh - 80px + 30px);
}

.section {
  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }
}

aside {
  border-right: 1px solid $border-color;
}

article {
  min-height: calc(100vh - 25px - 88px);
}

.space-between {
  @include flexbox;
  @include justify-content(space-between);
  @include align-items(center);
}

.main__head {
  @include flexbox;
  @include align-items(center);
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

// IMG block

.edit-mode {
  @include flexbox;
  @include align-items(center);

  div {
    @include flexbox;
    @include align-items(center);
    width: 100%;
    text-align: center;
    @include justify-content(center);
  }
}

.remove-link {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
}

.box {
  border: 1px solid $border-color;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  box-shadow: none;
}

// Modals

.modal-background {
  background-color: rgba($dark, 0.86);
  @include transition(all 0.2s ease-out);
}

.modal-content {
  padding: 28px;

  p {
    &:first-child {
      margin-bottom: 20px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.notification.is-success,
.notification.is-info {
  color: $dark;
}

.flash-messages {
  position: fixed;
  bottom: 20px;
  z-index: 999;

  @include tablet {
    width: 80vw;
    left: 10vw;
  }

  @include desktop {
    left: calc(50vw - 310px);
    width: 620px;
  }

  @include mobile {
    width: calc(100% - 40px);
    left: 20px;
  }
}

// Flexbox classes

.flex {
  @include flexbox;

  .is-display-mobile & {
    display: unset;
  }

  &:not(.flex-mobile) {
    @include mobile {
      display: unset;
    }
  }
}

.flex-touch {
  @include touch {
    @include flexbox;
  }
}

.flex-wrap-touch {
  @include touch {
    @include flex-wrap(wrap);
  }
}

.flex-touch {
  @include touch {
    @include flexbox;
  }
}

.dir-col-touch {
  @include touch {
    @include flex-direction(column);
  }
}

.wrap-touch {
  @include touch {
    @include flex-wrap(wrap);
  }
}

.flex-wrap {
  @include flex-wrap(wrap);

  &:not(.flex-wrap--mobile) {
    @include mobile {
      @include flex-wrap(unset);
    }
  }
}

.space-between {
  @include justify-content(space-between);
  @include align-items(center);
}

.flex-end {
  @include justify-content(flex-end);
}

.flex-between {
  @include justify-content(space-between);
}

.flex-column {
  @include flex-direction(column);
}

.justify-center {
  @include justify-content(center);
}

.align-start {
  @include align-items(start);
}

.align-center {
  @include align-items(center);
}

.align-end {
  @include align-items(flex-end);
}

.align-self {
  -webkit-align-self: normal;
  align-self: normal;
}

.align-self-start {
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

// Medias

.avatar-sm {
  width: 36px;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 50%;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 1px solid $light;
  position: relative;

  &--number {
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);
    background-color: $link;
    color: $light;
  }

  &:not(.avatar-sm--number) {
    margin-top: 5px;
  }
}

.avatar-md {
  @extend .avatar-sm;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}

.has-avatar {
  position: relative;
  z-index: 6;
  height: 100%;
  @include inline-flex;
  @include align-items(center);
}

@for $i from 1 through 6 {
  .has-avatar:nth-child(#{$i}) {
    left: calc(#{$i} * 8px * -1);
    z-index: #{6 - $i};
  }
}

.has-checkbox {
  .checkbox {
    top: -5.5px;
  }
}

.identity {
  height: 100%;
  @include flexbox;
  @include align-items(center);

  a+a {
    padding-left: math.div($column-gap, 2);
  }
}

//  List loading

@keyframes loadingAnimation {
  from {
    background-position: -100vw 0px;
  }

  to {
    background-position: 100vw 0px;
  }
}

@-webkit-keyframes loadingAnimation {
  from {
    background-position: -100vw 0px;
  }

  to {
    background-position: 100vw 0px;
  }
}

.form-loading,
.list-loading {
  width: 100%;
  height: auto;

  >div {
    width: 100%;
    height: 50px;
    margin-bottom: 1px;
    -webkit-animation-name: loadingAnimation;
    animation-name: loadingAnimation;
    -webkit-animation-duration: 4.6s;
    animation-duration: 4.6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    background-image: -webkit-linear-gradient(120deg,
        $white 20%,
        $grey-lighter 28%,
        $white 43%);
    background-image: -moz-linear-gradient(120deg,
        $white 20%,
        $grey-lighter 28%,
        $white 43%);
    background-image: linear-gradient(120deg,
        $white 20%,
        $grey-lighter 28%,
        $white 43%);
  }
}

.form-loading {
  >div {
    height: 50px;
    border-radius: 3px;
    border: 2px solid $grey-light;
    -webkit-animation-duration: 24s;
    animation-duration: 24s;
    background-image: -webkit-linear-gradient(120deg,
        $white-bis 20%,
        $grey-lighter 28%,
        $white-bis 43%);
    background-image: -moz-linear-gradient(120deg,
        $white-bis 20%,
        $grey-lighter 28%,
        $white-bis 43%);
    background-image: linear-gradient(120deg,
        $white-bis 20%,
        $grey-lighter 28%,
        $white-bis 43%);

    &.is-label {
      height: 11px;
      margin-bottom: 10px;
      width: 90px;
      border: 0;
    }
  }
}

// Bullets

$bullet-colors: (
  status-0: #00c3f9,
  status-1: #f05c22,
  status-2: #ff9d51,
  status-3: #ffe551,
  status-4: #51ff77,
  status-5: #5065ff
);

.bullet {
  height: 8px;
  width: 8px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: inline-block;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }
}

@each $status,
$bullet-color in $bullet-colors {
  .#{$status} .bullet {
    background: $bullet-color;

    &.is-white {
      background-color: #e8e8e8;
    }
  }

  .#{$status}.is-disabled .bullet {
    background-color: #e8e8e8;
  }

  .text-#{$status} {
    color: $bullet-color;
  }

  .badge.is-#{$status} {
    background-color: rgba($bullet-color, 0.1);
    color: darken($bullet-color, 26%);
  }
}

.text-status {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.comment {
  padding: math.div($gap, 2);
  background-color: $field-background;
  border: 1px solid $border;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;

  .has-v-separation & {
    width: 100%;

    @include tablet {
      position: relative;
      width: calc(100% - 30px);
      left: 15px;
    }

    @include widescreen {
      width: calc(100% - 15px);
      left: 0;
    }
  }

  ol,
  ul {
    margin-left: 24px;
  }

  ul {
    list-style-type: disc;
  }

  .date {
    color: $link;
    padding-top: $column-gap;
    display: inline-block;
    font-style: italic;
  }
}

//_ Tabs

.tabs {
  li>a {
    display: inline-block;
    padding-top: 2.2rem;
    padding-bottom: 1.5rem;
  }
}

//_ Helpers

.is-hidden-on-modal {
  .modal-content & {
    display: none;
  }
}

.is-only-on-modal {
  display: none;

  .modal-content & {
    display: block;
  }
}

.flex-col-on-modal {
  .modal-content & {
    @include flex-direction(column);
  }
}

.modal--large,
.modal-content-is-large .modal-content {
  @include tablet {
    min-width: 768px;
    max-width: 100vw;
  }
}

.no-border {
  border: 0;
}

.column.is-3 .color-list {
  min-width: 200%;

  @include fullhd {
    min-width: 100%;
  }

  @include mobile {
    min-width: 100%;
  }
}

.new-notifications {
  position: absolute;
  height: 19px;
  width: 19px;
  border-radius: 19px;
  border: 3px solid $white;
  right: -12px;
  top: -14px;
  background-color: $primary;

  @include flexbox;
  @include align-items(center);
  @include justify-content(center);

  >span {
    font-family: 'Rubik', sans-serif;
    font-size: 11px;
    color: $white;
  }
}

.notification-item {
  border-bottom: 1px solid $border-color;
}

.notification-dot {
  width: 9px;
  height: 9px;
  background-color: $link;
  border-radius: 9px;
  display: inline-block;
  margin-left: $gap;
}

.notification-excerpt {
  width: calc(100% - #{$gap} - 9px);
}

.bg-white-bis {
  background-color: $white-bis;
}

.bg-white-ter {
  background-color: $white-ter;
}

.bg-white {
  background-color: $white;
}

.bg-link {
  background-color: $link;
}

.bg-link-light {
  background-color: $link-light;
}

.bg-success {
  background-color: $success;
}

.bg-success-light {
  background-color: $success-light;
}

.bg-info {
  background-color: $info;
}

.bg-plan {
  background-color: #2effca;
}

.bg-warning {
  background-color: $warning;
}

.o-visible {
  overflow: visible !important;
}

.o-y-auto {
  overflow-y: auto !important;
}

.is-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.left-part {
  @include touch {
    max-width: calc(100% - #{$right-part-max-width-touch} - 1rem);
  }

  @include desktop {
    max-width: calc(100% - #{$right-part-max-width-desktop} - 2rem);
  }

  @include fullhd {
    max-width: calc(100% - #{$right-part-max-width} - 2rem);
  }
}

.right-part {
  @include touch {
    max-width: $right-part-max-width-touch;
  }

  @include desktop {
    max-width: $right-part-max-width;
  }
}
