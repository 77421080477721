.modal {
  z-index: 1030;

  &-content {
    @include touch {
      width: 80%;
    }
  }
  .modal-content {
    @include mobile {
      width: 100%;
      max-height: 100vh;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;

      padding-top: #{$gap * 2};
      overflow-y: auto;
    }
    @include tablet {
      overflow: hidden auto;
      width: 430px !important;
      padding: #{$gap * 2} !important;
    }
    .close {
      position: absolute !important;
      right: 0;
      top: 0;
      padding-right: 10px !important;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}
