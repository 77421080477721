@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kp0e2w')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kp0e2w')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kp0e2w##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-award1 {
  &:before {
    content: $icon-award1;
  }
}
.icon-bar-chart-21 {
  &:before {
    content: $icon-bar-chart-21;
  }
}
.icon-bar-chart1 {
  &:before {
    content: $icon-bar-chart1;
  }
}
.icon-log-in1 {
  &:before {
    content: $icon-log-in1;
  }
}
.icon-activity {
  &:before {
    content: $icon-activity;
  }
}
.icon-airplay {
  &:before {
    content: $icon-airplay;
  }
}
.icon-alert-circle {
  &:before {
    content: $icon-alert-circle;
  }
}
.icon-anchor {
  &:before {
    content: $icon-anchor;
  }
}
.icon-aperture {
  &:before {
    content: $icon-aperture;
  }
}
.icon-at-sign {
  &:before {
    content: $icon-at-sign;
  }
}
.icon-award {
  &:before {
    content: $icon-award;
  }
}
.icon-bar-chart-2 {
  &:before {
    content: $icon-bar-chart-2;
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart;
  }
}
.icon-battery-charging {
  &:before {
    content: $icon-battery-charging;
  }
}
.icon-bell {
  &:before {
    content: $icon-bell;
  }
}
.icon-book-open {
  &:before {
    content: $icon-book-open;
  }
}
.icon-book {
  &:before {
    content: $icon-book;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-box {
  &:before {
    content: $icon-box;
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-camera-off {
  &:before {
    content: $icon-camera-off;
  }
}
.icon-camera {
  &:before {
    content: $icon-camera;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-check3 {
  &:before {
    content: $icon-check3;
  }
}
.icon-chevrons-down {
  &:before {
    content: $icon-chevrons-down;
  }
}
.icon-chevrons-left {
  &:before {
    content: $icon-chevrons-left;
  }
}
.icon-chevrons-right {
  &:before {
    content: $icon-chevrons-right;
  }
}
.icon-chevrons-up {
  &:before {
    content: $icon-chevrons-up;
  }
}
.icon-chrome {
  &:before {
    content: $icon-chrome;
  }
}
.icon-circle {
  &:before {
    content: $icon-circle;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-cloud-snow {
  &:before {
    content: $icon-cloud-snow;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
  }
}
.icon-code {
  &:before {
    content: $icon-code;
  }
}
.icon-codepen {
  &:before {
    content: $icon-codepen;
  }
}
.icon-coffee {
  &:before {
    content: $icon-coffee;
  }
}
.icon-compass {
  &:before {
    content: $icon-compass;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-corner-up-right {
  &:before {
    content: $icon-corner-up-right;
  }
}
.icon-cpu {
  &:before {
    content: $icon-cpu;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-crop {
  &:before {
    content: $icon-crop;
  }
}
.icon-crosshair {
  &:before {
    content: $icon-crosshair;
  }
}
.icon-database {
  &:before {
    content: $icon-database;
  }
}
.icon-download-cloud {
  &:before {
    content: $icon-download-cloud;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-droplet {
  &:before {
    content: $icon-droplet;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-file-plus {
  &:before {
    content: $icon-file-plus;
  }
}
.icon-file-text {
  &:before {
    content: $icon-file-text;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-film {
  &:before {
    content: $icon-film;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-git-branch {
  &:before {
    content: $icon-git-branch;
  }
}
.icon-git-commit {
  &:before {
    content: $icon-git-commit;
  }
}
.icon-git-merge {
  &:before {
    content: $icon-git-merge;
  }
}
.icon-git-pull-request {
  &:before {
    content: $icon-git-pull-request;
  }
}
.icon-github {
  &:before {
    content: $icon-github;
  }
}
.icon-gitlab {
  &:before {
    content: $icon-gitlab;
  }
}
.icon-globe {
  &:before {
    content: $icon-globe;
  }
}
.icon-grid {
  &:before {
    content: $icon-grid;
  }
}
.icon-hard-drive {
  &:before {
    content: $icon-hard-drive;
  }
}
.icon-hash {
  &:before {
    content: $icon-hash;
  }
}
.icon-headphones {
  &:before {
    content: $icon-headphones;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-help-circle {
  &:before {
    content: $icon-help-circle;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-layers {
  &:before {
    content: $icon-layers;
  }
}
.icon-layout {
  &:before {
    content: $icon-layout;
  }
}
.icon-life-buoy {
  &:before {
    content: $icon-life-buoy;
  }
}
.icon-link-2 {
  &:before {
    content: $icon-link-2;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-log-in {
  &:before {
    content: $icon-log-in;
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin;
  }
}
.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-maximize-2 {
  &:before {
    content: $icon-maximize-2;
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-message-circle {
  &:before {
    content: $icon-message-circle;
  }
}
.icon-message-square {
  &:before {
    content: $icon-message-square;
  }
}
.icon-mic {
  &:before {
    content: $icon-mic;
  }
}
.icon-minimize-2 {
  &:before {
    content: $icon-minimize-2;
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize;
  }
}
.icon-monitor {
  &:before {
    content: $icon-monitor;
  }
}
.icon-more-horizontal {
  &:before {
    content: $icon-more-horizontal;
  }
}
.icon-more-vertical {
  &:before {
    content: $icon-more-vertical;
  }
}
.icon-move {
  &:before {
    content: $icon-move;
  }
}
.icon-navigation {
  &:before {
    content: $icon-navigation;
  }
}
.icon-package {
  &:before {
    content: $icon-package;
  }
}
.icon-paperclip {
  &:before {
    content: $icon-paperclip;
  }
}
.icon-pause-circle {
  &:before {
    content: $icon-pause-circle;
  }
}
.icon-percent {
  &:before {
    content: $icon-percent;
  }
}
.icon-phone-call {
  &:before {
    content: $icon-phone-call;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart;
  }
}
.icon-play-circle {
  &:before {
    content: $icon-play-circle;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}
.icon-plus-square {
  &:before {
    content: $icon-plus-square;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-power {
  &:before {
    content: $icon-power;
  }
}
.icon-printer {
  &:before {
    content: $icon-printer;
  }
}
.icon-save {
  &:before {
    content: $icon-save;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-server {
  &:before {
    content: $icon-server;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-share-2 {
  &:before {
    content: $icon-share-2;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-shield {
  &:before {
    content: $icon-shield;
  }
}
.icon-shopping-bag {
  &:before {
    content: $icon-shopping-bag;
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}
.icon-shuffle {
  &:before {
    content: $icon-shuffle;
  }
}
.icon-slack {
  &:before {
    content: $icon-slack;
  }
}
.icon-sliders {
  &:before {
    content: $icon-sliders;
  }
}
.icon-smartphone {
  &:before {
    content: $icon-smartphone;
  }
}
.icon-smile {
  &:before {
    content: $icon-smile;
  }
}
.icon-square {
  &:before {
    content: $icon-square;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-tablet {
  &:before {
    content: $icon-tablet;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
  }
}
.icon-target {
  &:before {
    content: $icon-target;
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up;
  }
}
.icon-toggle-right {
  &:before {
    content: $icon-toggle-right;
  }
}
.icon-trash-2 {
  &:before {
    content: $icon-trash-2;
  }
}
.icon-trending-up {
  &:before {
    content: $icon-trending-up;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-upload-cloud {
  &:before {
    content: $icon-upload-cloud;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-x-circle {
  &:before {
    content: $icon-x-circle;
  }
}
.icon-x-square {
  &:before {
    content: $icon-x-square;
  }
}
.icon-x {
  &:before {
    content: $icon-x;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-zap {
  &:before {
    content: $icon-zap;
  }
}
.icon-alert-octagon {
  &:before {
    content: $icon-alert-octagon;
  }
}
.icon-alert-triangle {
  &:before {
    content: $icon-alert-triangle;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-arrow-bottom-circle {
  &:before {
    content: $icon-arrow-bottom-circle;
  }
}
.icon-arrow-bottom {
  &:before {
    content: $icon-arrow-bottom;
  }
}
.icon-arrow-left-circle {
  &:before {
    content: $icon-arrow-left-circle;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right-circle {
  &:before {
    content: $icon-arrow-right-circle;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up-circle {
  &:before {
    content: $icon-arrow-up-circle;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-check1 {
  &:before {
    content: $icon-check1;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-edit-2 {
  &:before {
    content: $icon-edit-2;
  }
}
.icon-edit-3 {
  &:before {
    content: $icon-edit-3;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-user-plus {
  &:before {
    content: $icon-user-plus;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-check2 {
  &:before {
    content: $icon-check2;
  }
}
.icon-Author {
  &:before {
    content: $icon-Author;
  }
}
.icon-tick1 {
  &:before {
    content: $icon-tick1;
  }
}
