//_ Global style
.cal-month-view {
  @include mobile {
    position: relative;
    overflow-x: auto;

    .cal-cell-row.cal-header,
    .cal-days {
      min-width: 768px;
    }
  }
  .cal-day-badge {
    display: none;
  }

  .cal-day-number {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 31px;
  }
  .cal-header .cal-cell {
    font-weight: 400 !important;
  }
  .cal-day-cell {
    min-height: 108px;
    cursor: pointer;

    &.cal-today {
      background-color: $warning-light;
      .cal-day-number {
        font-size: 12px;
        color: $link;
        font-weight: 700;
      }
    }

    &:not(.cal-has-events) {
      position: relative;

      &:not(.cal-future):hover {
        &:before {
          @extend .icon;
          @extend .icon-plus;
          @include flexbox;
          @include align-items(center);
          @include justify-content(center);
          font-size: 3.3rem;
          position: absolute;

          height: 70px;
          width: 70px;
          top: calc(50% - 35px);
          left: calc(50% - 35px);
          border-radius: 50%;
          background-color: $link-alpha07;
          color: $link;
          line-height: 70px;
          text-align: center;
        }
      }
    }
  }

  .cal-cell-top {
    min-height: 31px;
    max-height: 31px;
  }
  .cal-events {
    position: relative;
    @include flex-direction(column);
    margin: 0;
    @include justify-content(stretch);
    .cal-event {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 0 6px;
      line-height: 19.25px;
      border-radius: 0;
      box-shadow: inset 0 1px 0 rgba(#fff, 0.2);
      height: fit-content !important;
      max-height: unset;
      min-height: min-content;
      flex-grow: 1;

      &[ng-reflect-contents]:before,
      > span {
        // position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        z-index: 4;
        content: attr(ng-reflect-contents) ' ';
        height: 100%;
        padding: 5px 8px;
        font-size: 11px;
        background: inherit;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        filter: invert(1) grayscale(1) contrast(99);
      }
      span {
        padding: 1px 8px;
      }
    }
  }
  .cal-cell-row:hover {
    background-color: $link-alpha03;
  }
  .cal-cell-row .cal-cell:not(.cal-future):hover,
  .cal-cell.cal-has-events.cal-open:not(.cal-future) {
    background-color: $link-alpha03;
  }
  .cal-cell-row .cal-cell.cal-future:hover,
  .cal-cell.cal-has-events.cal-open.cal-future {
    background-color: unset;
    cursor: initial;
  }
}

app-calendar {
  .button.is-light {
    &:hover {
      background: transparent;
    }
  }
}
my-calendar-tooltip-window {
  display: none !important;
}

//_ Colors
@mixin shades-of-color($count, $startcolor) {
  @for $i from 0 through $count {
    $background-color: darken($startcolor, $i * 2);

    .cal-month-view .cal-event.is-color-#{$i} {
      background-color: $background-color;
    }
  }
}

.cal-month-view .cal-event.is-color-holiday {
  background-color: #f5bd21; // Holiday  color
  cursor: default;
}

.cal-month-view .cal-event.is-color-day-off {
  background-size: auto auto;
  background-color: rgba(245, 189, 33, 1);
  background-image: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 10px,
    rgba(224, 172, 27, 1) 10px,
    rgba(224, 172, 27, 1) 20px
  );
  cursor: default;
}

@include shades-of-color(45, #ded2fb);

.has-calendar {
  @include touch {
    position: inherit;

    .mydp {
      position: inherit;

      .selector.selectorarrow {
        width: 100vw !important;
        left: 0 !important;
        position: absolute !important;
        top: 78px !important;
        box-shadow: none;
        border: 0;
        height: calc(100vh - 80px) !important;
        box-shadow: none;
        overflow: hidden !important;
        margin-top: 0;

        &:before {
          display: none;
        }
      }
    }

    .headerbtncell {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .mydp .daycell,
    .mydp .monthcell,
    .mydp .weekdaytitle,
    .mydp .yearcell {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

table > tbody > tr > td.daycell,
table > tbody > tr > td.monthcell,
table > tbody > tr > td.weekdaytitle,
table > tbody > tr > td.yearchangebtncell,
table > tbody > tr > td.yearcell,
table.header > tbody > tr > td {
  height: 41px !important;
}
.modal-content {
  .selector.selectorarrow {
    box-shadow: none;
    border-color: $white-ter;
    @include tablet {
      position: relative;
      left: -45px;
    }
  }

  *[formcontrolname='dateTo'] .selector.selectorarrow {
    left: calc(-45px - 155px);
  }
  *[formcontrolname='dateTo'] .mydp .selectorarrowleft:after,
  *[formcontrolname='dateTo'] .mydp .selectorarrowleft:before {
    left: calc(24px + 155px) !important;
  }
}
.mydp .datevalue,
.mydp .monthvalue,
.mydp .yearvalue {
  &:hover {
    color: $link;
    background-color: transparent !important;
  }
}
.mydp .yearchangebtncell,
.mydp .yearcell,
.mydp .monthcell {
  background-color: $white !important;
}
.mydp .caltable,
.mydp .daycell,
.mydp .monthcell,
.mydp .monthtable,
.mydp .weekdaytitle,
.mydp .yearcell,
.mydp .yeartable {
  color: $black;
}
.mydp .tablesingleday:hover,
.mydp .tablesinglemonth:hover,
.mydp .tablesingleyear:hover {
  background-color: transparent !important;
  color: $link;
}

.mydp .selectedday,
.mydp .selectedmonth,
.mydp .selectedyear,
.mydp .selectedday .datevalue,
.mydp .selectedmonth .monthvalue,
.mydp .selectedyear .yearvalue,
.mydp .tablesinglemonth.selectedmonth:hover,
.mydp .tablesingleyear.selectedyear:hover,
.mydp .selectedday .datevalue:hover,
.mydp .selectedmonth .monthvalue:hover,
.mydp .selectedyear .yearvalue:hover {
  background-color: $link !important;
  color: $white;
  border-radius: 2px;
}

// .is-1-of-1 {
//   min-height: 100% !important;
// }
// .is-1-of-2 {
//   min-height: 50% !important;
// }
// .is-1-of-3 {
//   min-height: 33% !important;
// }
// .is-1-of-4 {
//   min-height: 25% !important;
// }
// .is-1-of-5 {
//   min-height: 20% !important;
// }
// .is-1-of-6 {
//   min-height: 16.666667% !important;
// }
// .is-1-of- {
//   min-height: 14.28571% !important;
// }

.event-close {
  position: absolute;
  top: 2px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  .cal-event:hover & {
    opacity: 1;
    visibility: visible;
  }
}

.cannot-add-ts {
  pointer-events: none;

  .cal-month-view .cal-cell .cal-event {
    pointer-events: none !important;
  }
}
