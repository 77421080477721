// Breadcrumbs

app-breadcrumbs {
  @include tablet {
    position: absolute;
    left: calc(25% + 110px);
    z-index: 30;
    top: 30.5px;
  }
  @include desktop {
    left: calc(25% + 32px + 80px);
  }
  @include widescreen {
    left: calc(16.66667% + 32px + 80px);
  }
  @include fullhd {
    left: calc(12% + 110px);
  }

  ul {
    @include flexbox;
    @include align-items(center);
  }

  li {
    @include inline-flex;
    @include align-items(center);

    &:last-child a {
      color: inherit;
    }
  }
}
