.is-light {
  font-weight: 300;
}

.is-bold,
strong,
b {
  font-weight: 500;
}
span.highlighted {
  color: red !important;
}
